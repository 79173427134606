import * as React from "react"
import Layout from "../components/Layout"
import Nav from "../components/layout/Nav2"
import {StaticImage} from "gatsby-plugin-image"


const YachtDetails = () => {
  return (
<Layout>
    <Nav />
    <section className="my-16">

    <div className="max-w-[90%] ml-auto flex flex-col items-stretch md:flex-row md:container"> 
        <div className="w-full md:w-5/12"> 
            <div className="pt-16 md:pr-10 animate-in"> 
                <h1 className="text-4xl uppercase font-semibold"> NEXT CHAPTER <span className="ml-1 text-xs font-normal">Benetti Yacht for Sale</span> </h1> 
                    <div className="flex flex-wrap gap-x-4 mb-4 mt-2 text-lg font-semibold"> 
                        <div className="flex whitespace-nowrap gap-2"> <span>LENGTH</span> <span className="font-normal">181&apos;</span></div> 
                        <div className="flex whitespace-nowrap gap-2"> <span>MODEL</span> <span className="font-normal">Custom</span></div> 
                        <div className="flex whitespace-nowrap gap-2"> <span>BUILD</span> <span className="font-normal">2003</span></div> 
                        <div className="flex whitespace-nowrap gap-2"> <span>CABINS</span> <span className="font-normal">6</span> </div> 
                        <div className="flex whitespace-nowrap gap-2"> <span>ASKING PRICE</span> <span className="font-normal"> $19,900,000 </span></div> 
                    </div> 
                    <button data-modal-target="contactForm-modal" data-modal-toggle="contactForm-modal" type="button" className="modal-open my-7 rounded-lg text-lg text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-300 font-medium px-7 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
                        Contact Broker
                    </button> 

                    <div className="text-stone-900 mt-8 [&>p]:!text-left [&>p]:my-2.5">
                                <p>
                                    <strong className="text-stone-900">NEXT CHAPTER 180&apos;6&quot; Benetti 2003/2022<br/></strong>
                                    <em><br/><br/></em>
                                    Built by Benetti Yachts in 2003, NEXT CHAPTER is currently undergoing a Lloyd’s 20yr survey 
                                    including fresh hull paint, rebuilt engines, and transmissions. The 909 GT features the most spacious layout, 
                                    inside and out, of any 55m (180'6") vessel and rivals most 60m yachts. Her top highlight is a full-beam, on deck, 
                                    split-level master ensuite with a spectacular observation lounge and Owner’s study. Altogether, NEXT CHAPTER has 
                                    six spacious staterooms that sleep up to 13 guests. Exterior and interior designs penned by the famed Stefano 
                                    Natucci provide a timeless and elegant design that will surely impress. Among NEXT CHAPTER having an impressive 
                                    charter reputation, she’s able to cruise anywhere in the world with her 7000 nm range. Do not miss out on the 
                                    chance to experience true luxury aboard NEXT CHAPTER.<br/><br/>Key Features:
                                </p> 
                                <ul>
                                    <li>Full-Beam, Split-Level Primary Ensuite with Panoramic Views, Observation Lounge & Private Owner's Study</li>
                                    <li>Bridge Deck VIP Ensuite</li> 
                                    <li>Accommodations for 13 Guests in Six Spacious Staterooms Arranged as a Master Ensuite, VIP, Two Queens, Two Twins and One Pullman</li>
                                    <li>Lloyd's Class & MCA Compliant - 20-Year Lloyd's Survey in Progress with New Hull Paint, Engine Rebuild & Transmissions</li>
                                    <li>Astounding Range of 7000 Nautical Miles</li> <li>Four Zero Speed Stabilizers</li>
                                    <li>Inviting Sun Deck with a Spa Pool & Dedicated Air-Conditioned Gym with Proper Equipment</li>
                                    <li>Expansive Deck Spaces Throughout</li>
                                    <li>Large Beach Club & Swim Platform</li>
                                </ul> 
                                <p><em><br/>**Not for sale to US residents while in US Waters**</em></p>
                            </div> 
                            <div className="my-7 py-7 border-t border-gray-300 [&>h2]:font-semibold [&>h2]:text-xl"> 
                                <h2>Specifications</h2> 
                                <div className="my-4 ml-5 text-base grid grid-cols-1 lg:grid-cols-2 gap-2 [&>div>span:first-child]:font-semibold"> 
                                    <div className="flex whitespace-nowrap gap-2"> <span>Model:</span> <span>Custom</span> </div>
                                    <div className="flex whitespace-nowrap gap-2"> <span>Length (LOA):</span> <span>181' (55.02m)</span> </div>
                                    <div className="flex whitespace-nowrap gap-2"> <span>Year:</span> <span>2003</span> </div>
                                    <div className="flex whitespace-nowrap gap-2"> <span>Max Draft:</span> <span></span> </div>
                                    <div className="flex whitespace-nowrap gap-2"> <span>Location:</span> <span>Fort Lauderdale, FL, US</span> </div>
                                </div> 
                                <h2> Accommodations</h2> 
                                <div className="my-4 ml-5 text-base grid grid-cols-2 gap-2 [&>div>span:first-child]:font-semibold">
                                    <div className="flex whitespace-nowrap gap-2"> <span>Staterooms:</span> <span>6</span> </div>
                                </div> 
                            </div> 

                            <div className="[&>h2]:font-semibold [&>h2]:text-2xl">
                                <h2 className="text-black pb-5 uppercase">Other Yachts You May Like</h2>
                                <div className="grid grid-cols-2 gap-4">
                                    <div className="">
                                        <StaticImage
                                          src={"../images/yacht-details/yachting-ai-recommendation-ai-yacht-1.png"}
                                          alt={"Yachting.AI Tailored AI Yacht Browser"}
                                          placeholder="blurred"
                                          className="hover:-translate-y-1 hover:scale-110 transition ease-in-out duration-300 rounded-lg"
                                        />

                                        <div className="mt-5">
                                            <h3 className="font-bold uppercase">Serenity</h3>
                                            <h6>39&apos; Jeanneau 2020</h6>
                                            <h6>$425,000</h6>
                                            <h5 className="text-sky-800"> Palm City, FL, US</h5>
                                        </div>
                                    </div>

                                    <div className="">
                                        <StaticImage
                                          src={"../images/yacht-details/yachting-ai-recommendation-ai-yacht-2.png"}
                                          alt={"Yachting.AI Tailored AI Yacht Browser"}
                                          placeholder="blurred"
                                          className="hover:-translate-y-1 hover:scale-110 transition ease-in-out duration-300 rounded-lg"
                                        />

                                        <div className="mt-5">
                                            <h3 className="font-bold uppercase">Seahawk</h3>
                                            <h6>40&apos; Sea Ray 2020</h6>
                                            <h6>$350,000</h6>
                                            <h5 className="text-sky-800"> Tampa, FL, US</h5>
                                        </div>
                                    </div>

                                    <div className="">
                                        <StaticImage
                                          src={"../images/yacht-details/yachting-ai-recommendation-ai-yacht-4.png"}
                                          alt={"Yachting.AI Tailored AI Yacht Browser"}
                                          placeholder="blurred"
                                          className="hover:-translate-y-1 hover:scale-110 transition ease-in-out duration-300 rounded-lg"
                                        />

                                        <div className="mt-5">
                                            <h3 className="font-bold uppercase">Woody</h3>
                                            <h6>40&apos; Azimut 2021</h6>
                                            <h6>$500,000</h6>
                                            <h5 className="text-sky-800"> Fort Lauderdale, FL, US</h5>
                                        </div>
                                    </div>

                                    <div className="">
                                        <StaticImage
                                          src={"../images/yacht-details/yachting-ai-recommendation-ai-yacht-5.png"}
                                          alt={"Yachting.AI Tailored AI Yacht Browser"}
                                          placeholder="blurred"
                                          className="hover:-translate-y-1 hover:scale-110 transition ease-in-out duration-300 rounded-lg"
                                        />

                                        <div className="mt-5">
                                            <h3 className="font-bold uppercase">On Order</h3>
                                            <h6>40&apos; Princess 2019</h6>
                                            <h6>$350,000</h6>
                                            <h5 className="text-sky-800"> Fort Lauderdale, FL, US</h5>
                                        </div>
                                    </div>

                                </div>

                            </div>
                         </div>    
                    </div> 
                    <div className="w-full md:w-7/12"> 
                        <div className="md:p-7 md:sticky md:top-14 mt-10 md:mt-0" data-astro-transition-scope="astro-vaacmkmt-1"> 
                            <div data-te-lightbox-init="" className="grid grid-cols-2 grid-flow-row gap-4 [&>div]:col-span-2 md:[&>div]:col-span-1 md:[&>div:nth-child(7n+1)]:col-span-2"> 
                                <div className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-80"> 
                                    <img 
                                        src="https://images.boatsgroup.com/images/1/17/27/8891727_20230606123540606_1_XLARGE.jpg" 
                                        className="fs-lightbox absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" 
                                        alt="Yacht Photos Pics" 
                                        loading="lazy" 
                                        data-te-caption="" 
                                        width="1000" 
                                        height="1000" 
                                        decoding="async" 
                                        data-lightbox-index="0" 
                                    /> 
                                </div>
                                <div className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-80"> 
                                    <img 
                                        src="https://images.boatsgroup.com/images/1/17/27/8891727_20230606123542721_1_XLARGE.jpg" 
                                        className="fs-lightbox absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" 
                                        alt="Yacht Photos Pics" loading="lazy" data-te-caption="" width="1000" height="1000" decoding="async" data-lightbox-index="1" 
                                    /> 
                                </div>
                                <div className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-80"> 
                                    <img 
                                        src="https://images.boatsgroup.com/images/1/17/27/8891727_20230606123545002_1_XLARGE.jpg" 
                                        className="fs-lightbox absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" 
                                        alt="Yacht Photos Pics" 
                                        loading="lazy" 
                                        data-te-caption="" 
                                        width="1000" 
                                        height="1000" 
                                        decoding="async" 
                                        data-lightbox-index="2" 
                                    /> 
                                </div>
                                <div className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-80"> 
                                    <img 
                                        src="https://images.boatsgroup.com/images/1/17/27/8891727_20230606123547460_1_XLARGE.jpg" 
                                        className="fs-lightbox absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" 
                                        alt="Yacht Photos Pics" loading="lazy" data-te-caption="" width="1000" height="1000" decoding="async" data-lightbox-index="3" 
                                    /> 
                                </div>
                                <div className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-80"> 
                                    <img 
                                        src="https://images.boatsgroup.com/images/1/17/27/8891727_20230606123549499_1_XLARGE.jpg" 
                                        className="fs-lightbox absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" 
                                        alt="Yacht Photos Pics" loading="lazy" data-te-caption="" width="1000" height="1000" decoding="async" data-lightbox-index="4" 
                                    /> 
                                </div>
                                <div className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-80"> 
                                    <img 
                                        src="https://images.boatsgroup.com/images/1/17/27/8891727_20230606123551537_1_XLARGE.jpg" 
                                        className="fs-lightbox absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" 
                                        alt="Yacht Photos Pics" loading="lazy" data-te-caption="" width="1000" height="1000" decoding="async" data-lightbox-index="5" 
                                    /> 
                                </div>
                                <div className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-80"> 
                                    <img 
                                        src="https://images.boatsgroup.com/images/1/17/27/8891727_20230606123553550_1_XLARGE.jpg" 
                                        className="fs-lightbox absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" 
                                        alt="Yacht Photos Pics" loading="lazy" data-te-caption="" width="1000" height="1000" decoding="async" data-lightbox-index="6" 
                                    /> 
                                </div>
                                <div className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-80"> 
                                    <img 
                                        src="https://images.boatsgroup.com/images/1/17/27/8891727_20230606123555600_1_XLARGE.jpg" 
                                        className="fs-lightbox absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" 
                                        alt="Yacht Photos Pics" loading="lazy" data-te-caption="" width="1000" height="1000" decoding="async" data-lightbox-index="7" 
                                    /> 
                                </div>
                                <div className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-80"> 
                                    <img 
                                        src="https://images.boatsgroup.com/images/1/17/27/8891727_20230606123557584_1_XLARGE.jpg" 
                                        className="fs-lightbox absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" 
                                        alt="Yacht Photos Pics" loading="lazy" data-te-caption="" width="1000" height="1000" decoding="async" data-lightbox-index="8" 
                                    /> 
                                </div>
                                <div className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-80"> 
                                    <img 
                                        src="https://images.boatsgroup.com/images/1/17/27/8891727_20230606123559478_1_XLARGE.jpg" 
                                        className="fs-lightbox absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" 
                                        alt="Yacht Photos Pics" loading="lazy" data-te-caption="" width="1000" height="1000" decoding="async" data-lightbox-index="9" 
                                    /> 
                                </div>
                                <div className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-80"> 
                                    <img 
                                        src="https://images.boatsgroup.com/images/1/17/27/8891727_20230606123601492_1_XLARGE.jpg" 
                                        className="fs-lightbox absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" 
                                        alt="Yacht Photos Pics" loading="lazy" data-te-caption="" width="1000" height="1000" decoding="async" data-lightbox-index="10" 
                                    /> 
                                </div>
                                <div className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-80"> 
                                    <img 
                                        src="https://images.boatsgroup.com/images/1/17/27/8891727_20230606123635533_1_XLARGE.jpg" 
                                        className="fs-lightbox absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" 
                                        alt="Yacht Photos Pics" loading="lazy" data-te-caption="" width="1000" height="1000" decoding="async" data-lightbox-index="11" 
                                    /> 
                                </div>
                                <div className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-80"> 
                                    <img 
                                        src="https://images.boatsgroup.com/images/1/17/27/8891727_20230606123638252_1_XLARGE.jpg" 
                                        className="fs-lightbox absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" 
                                        alt="Yacht Photos Pics" loading="lazy" data-te-caption="" width="1000" height="1000" decoding="async" data-lightbox-index="12" 
                                    /> 
                                </div>
                                <div className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-80"> 
                                    <img 
                                        src="https://images.boatsgroup.com/images/1/17/27/8891727_20230606123603473_1_XLARGE.jpg" 
                                        className="fs-lightbox absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" 
                                        alt="Yacht Photos Pics" loading="lazy" data-te-caption="" width="1000" height="1000" decoding="async" data-lightbox-index="13" 
                                    /> 
                                </div>
                                <div className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-80"> 
                                    <img 
                                        src="https://images.boatsgroup.com/images/1/17/27/8891727_20230606123632988_1_XLARGE.jpg" 
                                        className="fs-lightbox absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" 
                                        alt="Yacht Photos Pics" loading="lazy" data-te-caption="" width="1000" height="1000" decoding="async" data-lightbox-index="14" 
                                    /> 
                                </div>
                                <div className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-80"> 
                                    <img 
                                        src="https://images.boatsgroup.com/images/1/17/27/8891727_20230606123605657_1_XLARGE.jpg" 
                                        className="fs-lightbox absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" 
                                        alt="Yacht Photos Pics" loading="lazy" data-te-caption="" width="1000" height="1000" decoding="async" data-lightbox-index="15" 
                                    /> 
                                </div>
                                <div className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-80"> 
                                    <img 
                                        src="https://images.boatsgroup.com/images/1/17/27/8891727_20230606123607733_1_XLARGE.jpg" 
                                        className="fs-lightbox absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" 
                                        alt="Yacht Photos Pics" loading="lazy" data-te-caption="" width="1000" height="1000" decoding="async" data-lightbox-index="16" 
                                    /> 
                                </div>
                                <div className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-80"> 
                                    <img 
                                        src="https://images.boatsgroup.com/images/1/17/27/8891727_20230606123609668_1_XLARGE.jpg" 
                                        className="fs-lightbox absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" 
                                        alt="Yacht Photos Pics" loading="lazy" data-te-caption="" width="1000" height="1000" decoding="async" data-lightbox-index="17" 
                                    /> 
                                </div>
                                <div className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-80"> 
                                    <img 
                                        src="https://images.boatsgroup.com/images/1/17/27/8891727_20230606123613094_1_XLARGE.jpg" 
                                        className="fs-lightbox absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" 
                                        alt="Yacht Photos Pics" loading="lazy" data-te-caption="" width="1000" height="1000" decoding="async" data-lightbox-index="18" 
                                    /> 
                                </div>
                                <div className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-80"> 
                                    <img 
                                        src="https://images.boatsgroup.com/images/1/17/27/8891727_20230606123616255_1_XLARGE.jpg" 
                                        className="fs-lightbox absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" 
                                        alt="Yacht Photos Pics" loading="lazy" data-te-caption="" width="1000" height="1000" decoding="async" data-lightbox-index="19" 
                                    /> 
                                </div>
                                <div className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-80"> 
                                    <img 
                                        src="https://images.boatsgroup.com/images/1/17/27/8891727_20230606123618673_1_XLARGE.jpg" 
                                        className="fs-lightbox absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" 
                                        alt="Yacht Photos Pics" loading="lazy" data-te-caption="" width="1000" height="1000" decoding="async" data-lightbox-index="20" 
                                    /> 
                                </div>
                                <div className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-80"> 
                                    <img 
                                        src="https://images.boatsgroup.com/images/1/17/27/8891727_20230606123620701_1_XLARGE.jpg" 
                                        className="fs-lightbox absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" 
                                        alt="Yacht Photos Pics" loading="lazy" data-te-caption="" width="1000" height="1000" decoding="async" data-lightbox-index="21" 
                                    /> 
                                </div>
                                <div className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-80"> 
                                    <img 
                                        src="https://images.boatsgroup.com/images/1/17/27/8891727_20230606123622841_1_XLARGE.jpg" 
                                        className="fs-lightbox absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" 
                                        alt="Yacht Photos Pics" loading="lazy" data-te-caption="" width="1000" height="1000" decoding="async" data-lightbox-index="22" 
                                    /> 
                                </div>
                                <div className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-80"> 
                                    <img 
                                        src="https://images.boatsgroup.com/images/1/17/27/8891727_20230606123625704_1_XLARGE.jpg" 
                                        className="fs-lightbox absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" 
                                        alt="Yacht Photos Pics" loading="lazy" data-te-caption="" width="1000" height="1000" decoding="async" data-lightbox-index="23" 
                                    /> 
                                </div>
                                <div className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-80"> 
                                    <img 
                                        src="https://images.boatsgroup.com/images/1/17/27/8891727_20230606123628560_1_XLARGE.jpg" 
                                        className="fs-lightbox absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" 
                                        alt="Yacht Photos Pics" loading="lazy" data-te-caption="" width="1000" height="1000" decoding="async" data-lightbox-index="24" 
                                    /> 
                                </div>
                                <div className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-80"> 
                                    <img 
                                        src="https://images.boatsgroup.com/images/1/17/27/8891727_20230606123630992_1_XLARGE.jpg" 
                                        className="fs-lightbox absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" 
                                        alt="Yacht Photos Pics" loading="lazy" data-te-caption="" width="1000" height="1000" decoding="async" data-lightbox-index="25" 
                                    /> 
                                </div>
                                <div className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-80"> 
                                    <img 
                                        src="https://images.boatsgroup.com/images/1/17/27/8891727_20230606123641007_1_XLARGE.jpg" 
                                        className="fs-lightbox absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" 
                                        alt="Yacht Photos Pics" loading="lazy" data-te-caption="" width="1000" height="1000" decoding="async" data-lightbox-index="26" 
                                    /> 
                                </div>
                                <div className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-80"> 
                                    <img 
                                        src="https://images.boatsgroup.com/images/1/17/27/8891727_20230606123643843_1_XLARGE.jpg" 
                                        className="fs-lightbox absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" 
                                        alt="Yacht Photos Pics" loading="lazy" data-te-caption="" width="1000" height="1000" decoding="async" data-lightbox-index="27" 
                                    /> 
                                </div>
                                <div className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-80"> 
                                    <img 
                                        src="https://images.boatsgroup.com/images/1/17/27/8891727_20230606123645808_1_XLARGE.jpg" 
                                        className="fs-lightbox absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" 
                                        alt="Yacht Photos Pics" loading="lazy" data-te-caption="" width="1000" height="1000" decoding="async" data-lightbox-index="28" 
                                    /> 
                                </div>
                                <div className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-80"> 
                                    <img 
                                        src="https://images.boatsgroup.com/images/1/17/27/8891727_20230606123647818_1_XLARGE.jpg" 
                                        className="fs-lightbox absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" 
                                        alt="Yacht Photos Pics" loading="lazy" data-te-caption="" width="1000" height="1000" decoding="async" data-lightbox-index="29" 
                                    /> 
                                </div>
                                <div className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-80"> 
                                    <img 
                                        src="https://images.boatsgroup.com/images/1/17/27/8891727_20230606123649945_1_XLARGE.jpg" 
                                        className="fs-lightbox absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" 
                                        alt="Yacht Photos Pics" loading="lazy" data-te-caption="" width="1000" height="1000" decoding="async" data-lightbox-index="30" 
                                    /> 
                                </div>
                                <div className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-80"> 
                                    <img 
                                        src="https://images.boatsgroup.com/images/1/52/7/8895207_20230613075824460_1_XLARGE.jpg" 
                                        className="fs-lightbox absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" 
                                        alt="Yacht Photos Pics" loading="lazy" data-te-caption="" width="1000" height="1000" decoding="async" data-lightbox-index="31" 
                                    /> 
                                </div>
                                <div className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-80"> 
                                    <img 
                                        src="https://images.boatsgroup.com/images/1/52/7/8895207_20230613075825457_1_XLARGE.jpg" 
                                        className="fs-lightbox absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" 
                                        alt="Yacht Photos Pics" loading="lazy" data-te-caption="" width="1000" height="1000" decoding="async" data-lightbox-index="32" 
                                    /> 
                                </div> 
                            </div> 
                        </div>  
                    </div> 
                </div>
    </section>

<script type="module" src="https://nefi.whitecube.ai/yachting-bot.umd.cjs?nefi-ai-version=1.5.15" data-company-id="873357140758888449" data-company-name="Yachting.AI" async=""></script>
</Layout>

    )
}

export default YachtDetails
